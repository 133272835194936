
import { Folder } from '@/models/FolderModel';
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    item: {
      type: Object,
      default: () => {
        return {} as unknown as Folder;
      },
    },
  },
  emits: ['onSelectViewOption', 'onSelectDeleteOption'],
  computed: {
    isFolder() {
      return this.item.type === 'folder' ? true : false;
    },
  },
  methods: {
    onSelectViewOption(item) {
      this.$emit('onSelectViewOption', item);
    },
    onSelectDeleteOption(item) {
      this.$emit('onSelectDeleteOption', item);
    },
  },
});
