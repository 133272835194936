
import { defineComponent } from 'vue';
export default defineComponent({
  props: {
    defaultName: {
      type: String,
      default: '',
    },
  },
  emits: ['close', 'save'],
  data: () => ({
    folderName: '',
  }),
  mounted() {
    this.focusInput();
  },
  methods: {
    cancel() {
      this.$emit('close');
    },
    save() {
      this.$emit('save', this.folderName);
    },
    focusInput() {
      this.folderName = this.defaultName;
      (this.$refs.new_folder_name as HTMLInputElement).focus();
    },
  },
  watch: {
    async defaultName(value) {
      this.folderName = await value;
    },
  },
});
